import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';
import translationTR from './locales/tr.json';

const savedLanguage = localStorage.getItem('commitedLanguage') || 'tr';
const resources = {
  en: {
    translation: translationEN
  },
  tr: {
    translation: translationTR
  }
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: savedLanguage, 
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;
