import React from "react";
import { certificate } from "../assets/index.js";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation(); 

  return (
    <div
        className={`bg-light-yellow flex justify-center items-start`}
      >
        <div className={` w-full`}>
          <section id={`terms`} className={`py-[48px] px-[48px] sm:flex-row lg:px-[80px] lg:py-[80px] max-w-[1480px] xl:mx-auto`}>
            <div className="">
            <div className="inline-flex items-end py-[6px] text-end px-4 bg-darkYellow rounded-[8px] mb-2 max-w-max">
                <p className="font-comfortaa font-normal text-darkBlue text-[14px] ml-2">
                  {t("termsMainTitle")} 
                </p>
              </div>
              <h1
                className={`font-comfortaa text-darkGreen w-full font-semibold text-[22px] lg:text-[28px] mt-5 `}
              >
                {t("termsHeadline")} 
              </h1>
            </div>
            <div className={`flex flex-col sm:flex-row items-center sm:py-10 py-3`}>
              <div className={`flex-1 flex justify-between flex-col gap-[16px]`}>
                {
                  t("terms", { returnObjects: true }).map(term => {
                    return (
                    <div key={term.index} className="flex gap-[12px] mt-2 md:mt-4 block px-[12px] py-[12px] md:px-[24px] md:py-[32px] bg-midGreen text-start rounded-[20px] border border-gray-200 rounded-lg shadow w-full md:w-[570px]">
                      <div className="w-[40px] min-w-[40px] h-[40px] bg-darkYellow text-darkBlue rounded-[40px] flex justify-center items-center text-[24px]">
                        {term.index}
                      </div>
                      <div>
                        <h5 className="font-comfortaa mb-2 text-[20px] font-bold tracking-tight text-gray-900 ">
                          {term.title}
                        </h5>
                        <p
                          className={`max-w-[770px] font-comfortaa  text-darkGreen text-[16px]`}
                        >
                          {term.description}
                        </p>
                      </div>
                  </div>
                  )
                  })
                }
              </div>
              <div className={`flex-1 flex-col justify-content items-center `}>
                <img
                  src={certificate}
                  alt=""
                  className={`w-[870px] mx-auto object-contain mt-3`}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
  );
};

export default Terms;
