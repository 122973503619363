import React from "react";
import { bannerSide } from "../assets/index.js";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation(); 

  return (
    <div
        className={`bg-light-yellow flex justify-center items-start`}
      >
        <div className={` w-full`}>
          <section id={`banner`} className={`py-[28px] px-[28px] sm:flex-row lg:px-[80px] lg:py-[32px] max-w-[1480px] xl:mx-auto`}>
            <a href="https://flycarbon.commited.app" target="_blank" className="banner">
              <div className="flex-col sm:flex-row flex items-center gap-[20px]">
                <img className="max-w-[120px] lg:max-w-[140px]" src={bannerSide}/>
                <h4 className="text-[16px] sm:text-[20px] lg:text-[22px] text-left font-comfortaa font-bold xl:max-w-[750px] lg:max-w-[800px] md:max-w-auto">  {t('banner.flyCarbon')}</h4>
              </div>
              <button className={`text-nowrap font-comfortaa text-white w-auto font-semibold bg-darkBlue rounded-[60px] px-[32px] py-[12px] hover:bg-midBlue`}>
              {t('banner.getCertificateCTA')}
              </button>
            </a>
          </section>
        </div>
      </div>
  );
};

export default Banner;
