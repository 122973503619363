import React from "react";
import { useTranslation } from 'react-i18next';
import { standout } from "../../assets";

const StandOut = () => {
  const { t } = useTranslation();

  return (
    <div className={`bg-darkBlue`}>
      <div className={`w-full`}>
        <section className={`flex md:flex-row flex-col py-[48px] px-[48px] lg:py-[70px] lg:px-[70px]`}>
          <div className={`flex-1 flex justify-content items-center flex-col`}>
            <h1 className={`font-comfortaa text-lightPurple w-full font-semibold md:leading-[48.8px] text-[28px] lg:text-[36px]  mt-5`}>
              {t('standout.title')}
            </h1>
            <p className={`max-w-[1276px] font-comfortaa font-normal text-white text-[16px] md:text-[20px] md:leading-[26.6px] mt-5`}>
              {t('standout.description')}
            </p>
            <div className={`w-full sm:mt-10 mt-5 flex flex-row justify-center items-start flex-shrink-0`}>
              <img src={standout} alt="" className={`w-[746px] object-contain`} />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default StandOut;
