import React, { useState } from "react";
import { close, logo, menu } from "../assets/index.js";
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation();

  const OpenClose = () => {
    console.log("toggle", toggle);
 setToggle((prev) => !prev)
  }
  return (
    <nav className={`w-full flex justify-between items-center py-6 max-w-[1920px] xl:mx-auto`}>
      <a href="/"><img src={logo} alt="" className={`w-[130px] sm:w-[180px]`} /></a>
      <ul className={`flex-1 sm:flex hidden justify-end list-none `}>
        {t("navLinks", { returnObjects: true }).map((navItem, i) => ( 
          <li
            key={i}
            className={`font-comfortaa font-semibold cursor-pointer text-[16px] text-darkGreen hover:bg-black-gradient
              ${i === t("navLinks").length - 1 ? "mr-0" : "mr-5 md:mr-10"} 
              `}
          >
            <a href={`/#${navItem.id}`}>{navItem.title}</a>
          </li>
        ))}
      </ul>

      <div className={`sm:hidden flex flex-1 justify-end items-center`}>
        <img
          src={toggle ? close : menu}
          alt="menu"
          className={`w-[16px] h-[16px] object-contain cursor-pointer`}
          onClick={OpenClose}
        />
        <div
          className={
            !toggle
              ? `flex hidden`
              : `p-6 absolute top-10 right-0 mx-4 my-4 min-w-[140px] border rounded-xl  sidebar  bg-white  `
          }
        >
          <ul className="list-none flex-col   justify-end items-center flex-1">
            {t("navLinks", { returnObjects: true }).map((navItem, index) => (
              <li
                key={index}
                className={`font-comfortaa font-semibold 
          cursor-pointer text-[16px] text-darkgreen ${
            index === t("navLinks").length - 1 ? "mb-0 " : "mb-4"
          }`}
              >
                <a href={`/#${navItem.id}`}>{navItem.title}</a>
              </li>
            ))}
            {/* <li className={`mt-8`}>
              <a
                id="demand-first"
                className={`peer bg-darkGreen p-4 rounded-xl text-white font-semibold w-[100px] `}
                onClick={() => setToggle2((prev) => !prev)}
              >
                Üye Ol veya Giriş Yap
              </a>
              <div
                className={
                  toggle2
                    ? `hidden`
                    : `absolute  z-10 peer-hover:flex hover:flex w-[180px] flex-col bg-white drop-shadow-lg right-7 mt-5`
                }
              >
                <Link
                  className="font-comfortaa font-semibold cursor-pointer px-5 py-3 hover:bg-gray-200"
                  to="/login"
                >
                  Giriş Yap
                </Link>
                <Link
                  className="font-comfortaa font-semibold cursor-pointer px-5 py-3 hover:bg-gray-200"
                  to="/register"
                >
                  Üye Ol
                </Link>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
