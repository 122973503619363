import React from "react";
import { useTranslation } from "react-i18next";
import { marketplace1 } from "../assets/index.js";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className={`bg-mid-green`}>
      <div className={`w-full`}>
        <section
          id="home"
          className={`flex flex-col items-center py-[48px] px-[24px] sm:flex-row lg:px-[80px] lg:py-[32px] max-w-[1480px] xl:mx-auto`}
        >
          <div className={`flex flex-1 flex-col`}>
            <div className="inline-flex items-center py-[6px] px-4 bg-darkGreen/[.2] rounded-[8px] mb-2 max-w-max">
              <p className="font-comfortaa font-normal text-darkGreen sm:text-[14px] text-[16px] leading-[30.8px] sm:leading-[25.8px]">
                {t("hero.welcomeMessage")}
              </p>
            </div>

            <div className={`flex flex-row `}>
              <div className={`flex flex-col`}>
                <h1
                  className={`flex-1 font-comfortaa font-semibold text-[22px] lg:text-[28px] mb-[16px] mb-[12px] md:mb-[12px] text-left text-darkGreen`}
                >
                  {t("hero.headline")}
                </h1>
                <p
                  className={`font-comfortaa font-normal text-white text-[16px] text-left max-w-[773px] mb-[24px] sm:mb-[12px]`}
                >
                  {t("hero.description")}
                </p>
              </div>
            </div>
          </div>

          <div
            className={`flex-1 flex justify-end items-center md:my-0 relative flex-shrink-0 `}
          >
            <img
              src={marketplace1}
              alt=""
              className={`w-[200px] sm:w-[350px] relative z-[5]`}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Hero;
