import { useTranslation } from 'react-i18next';
import { forindividuals } from "../../assets";

const ForIndividuals = () => {
  const { t } = useTranslation();

  return (
  <div className={`bg-midYellow`}>
    <div className={`w-full`}>
      <section
        className={`flex md:flex-row flex-col px-[48px] py-[48px] lg:px-[80px] lg:py-[80px] items-center`}
      >
      <div className={`flex flex-1 flex-col items-start`}>
        <div className={`flex flex-row items-center`}>
          <div className={`flex flex-col`}>
            <h1
              className={`flex-1 font-comfortaa font-semibold text-start text-[28px] lg:text-[52px] lg:leading-[47px] mb-[16px] mt-[12px] text-left text-darkBlue`}
            >
                  {t('forindividuals.title')}
            </h1>
            <p
              className={`font-comfortaa font-normal text-midBlue text-start ttext-[16px] md:text-[20px] md:leading-[26.6px] text-left max-w-[707px] mb-[24px]`}
            >
                  {t('forindividuals.description')}
            </p>
          </div>
        </div>
      </div>
        <div
        className={`flex-1 flex justify-end items-center relative flex-shrink-0 `}
      >
        <img
          src={forindividuals}
          alt=""
          className={` w-[700px] relative z-[5]`}
        />
      </div>
      </section>
    </div>
    </div>
  );
};

export default ForIndividuals;

