import React from "react"
import { useTranslation } from 'react-i18next';

const Partners = () => {
  const { t } = useTranslation();

  return (
    <div className={`bg-darkGreen`}>
        <div className="w-full flex flex-col justify-center items-center p-[50px] gap-[50px] font-comfortaa">
          <span className="w-auto font-comfortaa text-white text-[14px] w-auto font-semibold bg-[#015864] rounded-[8px] px-[16px] py-[12px]">{t("partner.label")}</span>
          <h1 className="text-[28px] text-white">{t("partner.title")}</h1>
          <div className="w-full flex flex-col lg:flex-row lg:flex-wrap justify-center items-center gap-[30px]">
            {t("partner.list", { returnObjects: true }).map((item, index) => (
              <div key={index} className="w-full lg:w-2/5 flex text-left items-center gap-5 text-white">
                {item.image && (<img src={`/assets/${item.image}`} alt={item.name} width={100} height={100} />)}
                <div className="flex flex-col gap-2">
                  {item.name && (<p className="font-semibold text-lg">{item.name}</p>)}
                  {item.text && (<p className="font-light text-sm">{item.text}</p>)}
                </div>
              </div>
            ))}
            <div className="w-2/5 flex text-left items-center gap-5 text-white"></div>
          </div>
        </div>
      </div>
  );
};

export default Partners;
