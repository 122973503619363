import React from "react";
import { useTranslation } from 'react-i18next';
import { sectors } from "../assets/index.js";

const ForWhom = () => {
  const { t } = useTranslation();

  return (
    <div className={`bg-dark-blue flex justify-center items-start`}>
      <div className={`w-full`}>
        <section 
          id="forwhom" 
          className={`flex md:flex-row flex-col items-center py-[48px] px-[48px] sm:flex-row lg:px-[80px] lg:py-[80px] max-w-[1480px] xl:mx-auto`}
        >
          <div className={`flex flex-1 flex-col`}>
            <div className="inline-flex items-center py-[6px] px-4 bg-midGreen rounded-[8px] mb-2 max-w-max">
              <p className="font-comfortaa font-normal text-darkBlue text-[14px] leading-[30.8px] ml-2">
                {t('forWhom.title')}
              </p>
            </div>
            <div className={`flex flex-row `}>
              <div className={`flex flex-col`}>
                <h1
                  className={`flex-1 font-comfortaa font-semibold text-[22px] lg:text-[28px] mb-[16px] mt-[12px] text-left text-midPurple`}
                >
                  <div>{t('forWhom.headline')}</div>
                  <div>{t('forWhom.subheadline')}</div>
                </h1>
                <p
                  className={`font-comfortaa font-normal text-white text-[16px] text-left max-w-[773px] mb-[24px]`}
                >
                  {t('forWhom.description')}
                </p>
              </div>
            </div>
          </div>
          <div
            className={`flex-1 flex justify-end items-center md:my-0 my-10 relative flex-shrink-0`}
          >
            <img
              src={sectors}
              alt=""
              className={`w-[300px] relative z-[5]`}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default ForWhom;
