import { useTranslation } from 'react-i18next';
import { joinus } from "../../assets";

const JoinUs = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-lightBlue">
      <div className="w-full">
        <section className="flex md:flex-row flex-col px-[48px] py-[48px] lg:px-[70px] lg:py-[70px] ">
          <div className="flex-1 flex justify-content items-center flex-col">
            <div className="w-full sm:mt-10 mt-5 flex flex-row justify-center items-start flex-shrink-0">
              <img src={joinus} alt="" className="w-[702px] object-contain" />
            </div>
            <h1 className="font-comfortaa text-darkBlue w-full font-semibold text-[28px] lg:text-[52px] lg:leading-[47px] mt-5">
              {t('joinus.title')}
            </h1>
            <p className="max-w-[1103px] font-comfortaa font-normal text-midBlue text-[16px] md:text-[20px] md:leading-[26.6px] mt-5">
              {t('joinus.description')}
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default JoinUs;
