import React, { useEffect } from "react";
import * as ReactGA from "react-ga";
import Hero from "./sections/Hero.jsx";
import Partners from "./sections/Partners.jsx";
import About from "./sections/About.jsx";
import Business from "./sections/Business.jsx";
import ForWhom from "./sections/ForWhom.jsx";
import Abilities from "./sections/Abilities.jsx";
import Terms from "./sections/Terms.jsx";
import ContactUs from "./sections/ContactUs.jsx";
import Banner from "./sections/Banner.jsx";

const ShowCase = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
      <Hero />
      <Banner />
      <Partners />
      <About />
      <Business />
      <ForWhom />
      <Abilities />
      <Terms />
      <ContactUs />
    </>
  );
};

export default ShowCase;
