import React from "react";
import { useTranslation } from 'react-i18next';
import { about } from "../assets";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className={`bg-lightBlue flex justify-center items-center`}>
      <div className={`w-full`}>
        <section
          id={`business`}
          className={`flex md:flex-row flex-col py-[48px] px-[48px] lg:py-[80px] lg:px-[80px]`}
        >
          <div className={`flex-1 flex justify-content items-center flex-col`}>
            <span
              className={`w-auto font-comfortaa text-darkBlue text-[14px] w-auto font-semibold bg-darkYellow rounded-[8px] px-[16px] py-[12px]`}
            >
              {t('about.title')}
            </span>
            <h1
              className={`font-comfortaa text-darkBlue w-full font-semibold text-[22px] lg:text-[28px] mt-5 `}
            >
              {t('about.headline')}
            </h1>
            <p
              className={`max-w-[926px] font-comfortaa font-normal text-midBlue text-[16px] mt-5`}
            >
              {t('about.description')}
            </p>
            <a href="/about"
              className={`w-auto font-comfortaa text-white w-auto font-semibold bg-darkBlue rounded-[60px] px-[32px] py-[12px] mt-[24px] hover:bg-midBlue`}
            >
              {t('about.cta')}
            </a>
            <div
              className={`w-full sm:mt-10 px-5 mt-5 flex flex-row justify-center items-start flex-shrink-0`}
            >
              <img src={about} alt="" className={`md:w-[746px] object-contain`} />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
