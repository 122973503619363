import React from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { Route, Routes } from "react-router-dom";
import ShowCase from "./ShowCase.jsx";
import About from "./About.jsx";
import * as ReactGA from "react-ga";
import LogIn from "./v2/pages/LogIn.jsx";
import Register from "./v2/pages/Register.jsx";
import Navbar from "./layouts/Navbar.jsx";
import Footer from "./layouts/Footer.jsx";
import { I18nextProvider } from 'react-i18next'; 
import i18n from './i18n';

function App() {
  const TRACKING_ID = "UA-259575828-1";
  ReactGA.initialize(TRACKING_ID);

  return (
    <I18nextProvider i18n={i18n}>
      <div className="bg-dimWhite w-full overflow-hidden">
        <div className={`sm:px-16 px-6 sm:px-16 px-6`}>
          <div className={`w-full`}>
            <Navbar />
          </div>
        </div>
        <Routes>
          <Route path="/" element={<ShowCase />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/about" element={<About />} />
          <Route path="/register" element={<Register />} />
          <Route path="/hakkimizda" element={<Register />} />
        </Routes>
        <div className={`bg-[#DDDDDD] sm:px-16 px-6 sm:px-16 px-6`}>
          <div className={`w-full`}>
            <Footer />
          </div>
        </div>
      </div>
    </I18nextProvider>
  );
}

export default App;
