import React from "react";
import { useTranslation } from 'react-i18next';
import { socialMedia } from "../constants/index.js";
import LanguageSelection from "../components/language-selection";
import { FaPhone, FaFax, FaEnvelope } from "react-icons/fa";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <section className={`flex justify-center items-center sm:py-10 py-6 flex-col`}>    {/*<div className={`flex justify-center items-start md:flex-row flex-col mb-8 w-full`}>*/}
    {/*    <div className={`flex-1 flex flex-col justify-start mr-10`}>*/}
    {/*        <img src={logo} alt="Logo" className={`w-[266px] h-[72px] object-contain`}/>*/}
    {/*        <p className={`font-comfortaa font-normal text-dimWhite text-[18px] leading-[30.8px] mt-4 max-w-[310px]`}>A new way to make the payments easy, reliable*/}
    {/*            and secure.</p>*/}
    {/*    </div>*/}
      {/*</div>*/}
      <div className={`w-full flex justify-between items-center md:flex-row flex-col gap-[10px] border-b pb-5`}>
        <p className={`font-comfortaa font-normal text-center text-[16px] text-[#637381]`}>
          <LanguageSelection dropdownPosition="top" />
        </p>
        <p className={`font-comfortaa font-normal text-center text-[16px] text-[#637381]`}>
          {t('footer.investment')}
        </p>
        <div className={`flex flex-row md:mt-0 mt-6`}>
          {socialMedia.map((social, index) => (
            <a href={social.link} key={social.id} target="_blank">
              <img
                key={social.id}
                src={social.icon}
                alt={social.id}
                className={`w-[21px] h-[21px] object-contain cursor-pointer ${
                  index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
                }`}
              />
            </a>
          ))}
        </div>
      </div>
      <div className="w-full mt-4 text-[#637381]">
        <div className="w-full text-left">
          <h4 className="mb-2 text-body-color">
            COMMİTED DANIŞMANLIK A.Ş.
          </h4>
          <p className="text-xs text-body-color">
            ESENTEPE MAH. BÜYÜKDERE CAD. FİBABANKA GENEL MÜDÜRLÜĞÜ NO:
            129 İÇ KAPI NO: 1 ŞİŞLİ/ İSTANBUL
          </p>
          <div className="flex gap-3 mt-2">
            <span className="text-xs text-body-color flex gap-2 items-center">
              <FaPhone /> +90 216 235 0626
            </span>
            <span className="text-xs text-body-color flex gap-2 items-center">
              <FaFax />+90 212 381 8576
            </span>
            <span className="text-xs text-body-color flex gap-2 items-center">
              <FaEnvelope />info@commited.app
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
