import { instagram, linkedin } from "../assets";

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/commited_tr",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://tr.linkedin.com/company/commited-app",
  },
];


