import React from "react";
import { useTranslation } from 'react-i18next';
import { followmarket } from "../../assets";

const FollowMarket = () => {
  const { t } = useTranslation();

  return (
    <div className={`bg-lightWhite`}>
      <div className={`w-full`}>
        <section className={`flex md:flex-row flex-col px-[48px] py-[48px] lg:px-[80px] lg:py-[80px] items-center`}>
          <div className={`flex-1 flex justify-start items-center relative flex-shrink-0 `}>
            <img src={followmarket} alt="" className={` w-[700px] relative z-[5] mb-5 lg:mb-0`} />
          </div>
          <div className={`flex flex-1 flex-col items-end`}>
            <div className={`flex flex-row items-center`}>
              <div className={`flex flex-col`}>
                <h1 className={`flex-1 font-comfortaa font-semibold text-end text-[28px] lg:text-[52px] lg:leading-[47px] mb-[16px] mt-[12px] text-left text-darkBlue`}>
                  {t('followMarket.title')}
                </h1>
                <p className={`font-comfortaa font-normal text-midBlue text-end text-[16px] md:text-[20px] md:leading-[26.6px] text-left max-w-[707px]`}>
                  {t('followMarket.description')}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FollowMarket;
