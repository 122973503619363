import React, { useState } from "react";
import { GrMail, GrChat, GrCircleInformation } from "react-icons/gr";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Spinner from "../components/Spinner.jsx";
import * as ReactGA from "react-ga";
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [detail, setDetail] = useState("");
  const [consent, setConsent] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const addDemand = async () => {
    let mailPattern = new RegExp(
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
    );

    if (!mailPattern.test(email)) {
      return showToastMessage("error", t("contactUs.validEmail"));
    }

    if (mailPattern.test(email)) {
      setLoading(true);

      ReactGA.event({
        category: "Customer acquired",
        action: "Sertifika Satın Al",
        label: "",
        value: "Information=>" + " " + email,
      });

      await axios
        .post(
          `/api/demands`,
          {
            email: email,
            subject: subject,
            message: detail
          },
          {}
        )
        .then((data) => {
          clearInputs();
          showToastMessage("success", t("contactUs.successMessage"));
        })
        .catch((data) => {
          clearInputs();
          showToastMessage("error", t("contactUs.errorMessage"));
        });
    }
  };

  const clearInputs = () => {
    setLoading(false);
    setEmail("");
    setSubject("");
    setDetail("");
    setConsent(false);
  }

  const showToastMessage = (type, message, options) => {
    const defaultOptions = {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    }

    return toast[type](message, {...defaultOptions, ...options});
  }

  const canBeSubmitted = () => {
    return consent ? setIsDisabled(true) : setIsDisabled(false);
  };

  const onCheckboxClick = () => {
    setConsent(!consent);
    return canBeSubmitted();
  };

  return (
    <section
      id={`contactUs`}
      className={`sm:py-16 py-6 flex flex-col items-center justify-center   `}
    >
      <div
        className={`flex justify-center items-center flex-1 flex-col sm:px-16 px-6 xl:px-0 `}
      >
        <div className="inline-flex py-[6px] sm:text-end px-4 bg-darkYellow rounded-[8px] mb-2 max-w-max">
          <p className="font-comfortaa font-normal text-darkBlue text-[14px] leading-[30.8px] ml-2">
            {t('contactUs.contact')}
          </p>
        </div>
        <h1
          className={`font-comfortaa text-darkGreen w-full font-semibold text-[22px] lg:text-[28px] mt-5 `}
        >
          {t('contactUs.getCommitedApp')}
        </h1>
        <p
          className={`max-w-[770px] font-comfortaa font-normal text-darkGreen text-[16px] mt-5`}
        >
          {t('contactUs.connectWithUs')}
        </p>
      </div>
      <div className={`flex sm:flex-row flex-col p-6 py-3`}>
        <div className={`flex-1 flex flex-col mt-5`}>
          {/*<div className="relative flex w-full flex-wrap items-stretch mb-3">*/}
          {/*   <span*/}
          {/*       className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 left-0 pl-3 py-3">*/}
          {/*     <BsPersonFill/>*/}
          {/* </span>*/}
          {/*    <input type="text" placeholder="Ad - Soyad"*/}
          {/*           className="px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full pl-10"*/}
          {/*           value={fullName} onChange={e => setFullName(e.target.value)}/>*/}
          {/*</div>*/}
          {/*<div className="relative flex w-full flex-wrap items-stretch mb-3">*/}
          {/*   <span*/}
          {/*       className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 left-0 pl-3 py-3">*/}
          {/*     <HiBuildingLibrary/>*/}
          {/* </span>*/}
          {/*    <input type="text" placeholder="Şirket"*/}
          {/*           className="px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full pl-10"*/}
          {/*           value={company} onChange={e => setCompany(e.target.value)}/>*/}
          {/*</div>*/}
          {/*<div className="relative flex w-full flex-wrap items-stretch mb-3">*/}
          {/*   <span*/}
          {/*       className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 left-0 pl-3 py-3">*/}
          {/*     <FaIndustry/>*/}
          {/* </span>*/}
          {/*    <input type="text" placeholder="Sektör"*/}
          {/*           className="px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full pl-10"*/}
          {/*           value={industry} onChange={e => setIndustry(e.target.value)}*/}
          {/*    />*/}
          {/*</div>*/}
          <form id="customer-email">
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 left-0 pl-3 py-3">
                <GrMail />
              </span>
              <input
                type="email"
                placeholder={t("contactUs.emailPlaceholder")}
                className=" px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none w-full pl-10"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 left-0 pl-3 py-3">
                <GrCircleInformation />
              </span>
              <input
                type="text"
                placeholder={t("contactUs.subjectPlaceholder")}
                className="px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border border-slate-300 outline-none w-full pl-10"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 left-0 pl-3 py-3 mt-2">
                <GrChat />
              </span>
              <textarea
                placeholder={t("contactUs.messagePlaceholder")}
                className="px-3 py-4 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-base border border-slate-300 outline-none w-full pl-10"
                value={detail}
                onChange={e => setDetail(e.target.value)}
              ></textarea>
            </div>
          </form>
          <div className={`flex`}>
            <label
              htmlFor="default-checkbox"
              className="font-comfortaa text-darkGreen text-[16px]"
            >
              <input
                className="mr-3"
                id="default-checkbox"
                type="checkbox"
                onClick={onCheckboxClick}
              />
              {t("contactUs.consentMessage")}
            </label>
          </div>
          <div className="mt-4">
            {loading && <Spinner />}
            {!loading && (
              <button
                id="submit-demand"
                className={`${isDisabled || !email || !subject || !detail  ? "bg-gray-500" : "bg-darkGreen"
                  } p-4 rounded-xl text-white font-semibold w-[200px] `}
                disabled={isDisabled || !email || !subject || !detail}
                onClick={addDemand}
              >
                {t("contactUs.submitButton")}
              </button>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default ContactUs;
