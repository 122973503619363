import React from "react";
import { useTranslation } from 'react-i18next';
import { world } from "../assets/index.js";

const Business = () => {
  const { t } = useTranslation();

  return (
    <div className={`bg-midYellow flex justify-center items-center`}>
      <div className={`w-full`}>
        <section
          id={`business`}
          className={`flex md:flex-row flex-col py-[48px] px-[48px] lg:py-[80px] lg:px-[80px]`}
        >
          <div className={`flex-1 flex justify-content items-center flex-col`}>
            <span
              className={`w-auto font-comfortaa text-darkGreen w-auto font-semibold bg-midPurple rounded-[8px] px-[16px] py-[12px] text-[14px]`}
            >
              {t('business.title')}
            </span>
            <h1
              className={`font-comfortaa text-darkBlue w-full font-semibold text-[22px] lg:text-[28px] mt-5`}
            >
              {t('business.headline')}
            </h1>
            <p
              className={`max-w-[926px] font-comfortaa font-normal text-midBlue text-[16px] mt-5`}
            >
              {t('business.description')}
            </p>
            <div
              className={`w-full sm:mt-10 mt-5 px-5 flex flex-row justify-center items-start flex-shrink-0`}
            >
              <img src={world} alt="" className={`w-[746px] object-contain`} />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Business;
