import React from "react";
import { useTranslation } from 'react-i18next';
import { abilities } from "../assets/index.js";

const Abilities = () => {
  const { t } = useTranslation();

  return (
    <div className={`bg-light-purple flex justify-center items-start`}>
      <div className={`w-full`}>
        <section 
          id="abilities"
          className={`flex md:flex-row flex-col items-center py-[48px] px-[48px] sm:flex-row lg:px-[80px] lg:py-[80px] max-w-[1480px] xl:mx-auto`}
        >
          <div
            className={`flex-1 flex  justify-start items-center md:my-0 my-10 relative flex-shrink-0`}
          >
            <img
              src={abilities}
              alt=""
              className={`w-[300px] lg:w-[400px] relative z-[5]`}
            />
          </div>
          <div className={`flex flex-1 flex-col sm:items-end`}>
            <div className="inline-flex py-[6px] sm:text-end px-4 bg-darkYellow rounded-[8px] mb-2 max-w-max">
              <p className="font-comfortaa font-normal text-darkBlue text-[14px] leading-[30.8px] ml-2">
                {t('abilities.title')}
              </p>
            </div>
            <div className={`flex flex-row `}>
              <div className={`flex flex-col`}>
                <h1
                  className={`flex-1 font-comfortaa font-semibold text-[22px] lg:text-[28px] text-start sm:text-end mb-[16px] mt-[12px] text-darkBlue`}
                >
                  <div>{t('abilities.headline')}</div>
                  <div>{t('abilities.subheadline')}</div>
                </h1>
                <p
                  className={`font-comfortaa font-normal text-midBlue sm:text-end text-[16px] text-left max-w-[814px] leading-[26px] mb-[24px]`}
                >
                  {t('abilities.description')}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Abilities;
