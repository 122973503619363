import React from "react";
import StandOut from "./sections/AboutSections/StandOut.jsx";
import ForBusinesses from "./sections/AboutSections/ForBusinesses.jsx";
import ForIndividuals from "./sections/AboutSections/ForIndividuals.jsx";
import FollowMarket from "./sections/AboutSections/FollowMarket.jsx";
import JoinUs from "./sections/AboutSections/JoinUs.jsx";
import TrackImpact from "./sections/AboutSections/TrackImpact.jsx";

const About = () => {
  return (
    <>
      <StandOut/>
      <ForBusinesses/>
      <ForIndividuals/>
      <FollowMarket/>
      <JoinUs/>
      <TrackImpact/>
    </>
  );
};

export default About;
